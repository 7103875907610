import { navigate } from "gatsby"
import { useAdminLogin } from "medusa-react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../fundamentals/button"
import InfoIcon from "../../fundamentals/icons/info-icon"
import SigninInput from "../../molecules/input-signin"
import CopyToClipboard from "../../atoms/copy-to-clipboard"
import Modal from "../../molecules/modal"
import { styled } from '@stitches/react';

type FormValues = {
  email: string
  password: string
}

type LoginCardProps = {
  toResetPassword: () => void
}

type VeriModel = {
  veriCode: string;
}

const VerifyGoogle = ({ show, onClose }) => { 
  const { register, handleSubmit, reset } = useForm<VeriModel>()
  const FormLabel = styled('div', {
    width: '100px',
    textAlign: 'right',
    fontSize: '14px',
    paddingRight: '12px',
    color: '#101010'
  })
  const onSubmit = (value) => { 
    console.log('----谷歌验证-----', value)
  }
  return (
    <Modal open={show} handleClose={() => { }}>
      <Modal.Header handleClose={onClose}>
        <h4 className="text-[18px] leading-[16px]">谷歌验证</h4>
      </Modal.Header>
      <Modal.Body className="w-[500px]">
        <div className="flex flex-col w-full pt-8 pb-5 px-7">
          <div className="flex items-center w-full mb-6">
            <FormLabel><span className="mr-[4px] text-[#ff4d4f] leading-1">*</span>谷歌验证：</FormLabel>
            <form>
              <SigninInput
                placeholder="谷歌验证码"
                name="veriCode"
                ref={register({ required: true })}
              />
            </form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-end">
        <Button
          className="rounded-rounded w-[100px] inter-base-regular mr-4"
          variant="secondary"
          size="medium"
          onClick={onClose}
        >
          取消
        </Button>
        <Button
          className="rounded-rounded w-[100px] inter-base-regular"
          variant="primary"
          size="medium"
          onClick={handleSubmit(onSubmit)}
        >
          确定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const BindingGoogleVer = ({ show, onClose }) => { 
  const { register, handleSubmit, reset } = useForm<VeriModel>()
  const FormLabel = styled('div', {
    width: '100px',
    textAlign: 'right',
    fontSize: '14px',
    paddingRight: '12px',
    color: '#101010'
  })
  const onSubmit = (value) => { 
    console.log('---value---', value)
  }
  return (
    <Modal open={show} handleClose={() => { }}>
      <Modal.Header handleClose={onClose}>
        <h4 className="text-[18px] leading-[16px]">绑定谷歌验证</h4>
      </Modal.Header>
      <Modal.Body className="w-[600px]">
        <div className="flex flex-col w-full pt-8 pb-5 px-7">
          <div className="flex items-center w-full mb-6">
            <FormLabel>账号：</FormLabel>
            <div className="flex items-center">
              <span className="mr-2">Metaicon--15359600600</span>
              <CopyToClipboard
                value={ `data?.address` }
                showValue={false}
                iconSize={14}
              />
            </div>
          </div>
          <div className="flex items-center w-full mb-6">
            <FormLabel>秘钥：</FormLabel>
            <div className="flex items-center">
              <span className="mr-2">HDCYWPBJFXKPDRKLEYJEEWYOUOKKGVIM</span>
              <CopyToClipboard
                value={ `data?.address` }
                showValue={false}
                iconSize={14}
              />
            </div>
          </div>
          <div className="flex items-center w-full mb-6">
            <FormLabel><span className="mr-[4px] text-[#ff4d4f] leading-1">*</span>谷歌验证：</FormLabel>
            <form>
              <SigninInput
                placeholder="谷歌验证码"
                name="veriCode"
                ref={register({ required: true })}
              />
            </form>
          </div>
          <div className="p-5 flex bg-[#e6f7ff] border border-[#91d5ff] rounded-[4px]">
            <span className="mt-1"><InfoIcon color="#91d5ff" size={18} /></span>
            <div className="pl-2">
              <p className="text-[#101010]">1、安装在“Google Authenticator”（身份验证器）应用程序中，点击“新增”，然后选择“手动输入验证码”。</p>
              <p className="text-[#101010]">2、粘贴上面的“密钥”到身份验证器的“密钥”输入栏，并填写您的MetaIcons Market账号（手机号），点击完成。</p>
              <p className="text-[#101010]">3、复制身份验证器里的“谷歌验证码”，粘贴到上面的“谷歌验证”输入栏。</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-end">
        <Button
          className="rounded-rounded w-[100px] inter-base-regular mr-4"
          variant="secondary"
          size="medium"
          onClick={onClose}
        >
          取消
        </Button>
        <Button
          className="rounded-rounded w-[100px] inter-base-regular"
          variant="primary"
          size="medium"
          onClick={handleSubmit(onSubmit)}
        >
          确定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const LoginCard: React.FC<LoginCardProps> = ({ toResetPassword }) => {
  const [isInvalidLogin, setIsInvalidLogin] = useState(false)
  const { register, handleSubmit, reset } = useForm<FormValues>()
  const login = useAdminLogin()

  const [show, setShow] = useState(false)
  const [vshow, setVshow] = useState(false)

  const toInvite = () => { 
    navigate('/invite')
  }

  const onSubmit = (values: FormValues) => {
    login.mutate(values, {
      onSuccess: (data) => {
        navigate("/a/home")
      },
      onError: () => {
        setIsInvalidLogin(true)
        reset()
      },
    })
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-center">
          <span className="mt-4 mb-[57px] inter-2xlarge-semibold text-grey-90">
            ICONME
          </span>
          <SigninInput
            placeholder="电子邮箱..."
            name="email"
            ref={register({ required: true })}
            autoComplete="email"
          />
          <SigninInput
            placeholder="密码..."
            type={"password"}
            name="password"
            ref={register({ required: true })}
            autoComplete="current-password"
          />
          {isInvalidLogin && (
            <span className="w-full mt-2 text-rose-50 inter-small-regular">
              These credentials do not match our records
            </span>
          )}
          <Button
            className="rounded-rounded mt-4 w-[320px] inter-base-regular"
            variant="primary"
            size="large"
            type="submit"
            loading={login.isLoading}
          >
            登录
          </Button>
          <span
            className="mt-8 cursor-pointer inter-small-regular text-grey-50"
            onClick={toResetPassword}
          >
            重置密码
          </span>
          <span
            className="mt-4 cursor-pointer inter-small-regular text-grey-50"
            onClick={toInvite}
          >
            激活账号
          </span>
        </div>
      </form>
      <BindingGoogleVer show={show} onClose={() => setShow(false)}></BindingGoogleVer>
      <VerifyGoogle show={vshow} onClose={() => setVshow(false)}></VerifyGoogle>
    </>
  )
}

export default LoginCard
