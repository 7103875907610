import { useAdminSendResetPasswordToken } from "medusa-react"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import CheckCircleIcon from "../../fundamentals/icons/check-circle-icon"
import SigninInput from "../../molecules/input-signin"

type ResetTokenCardProps = {
  goBack: () => void
}

type FormValues = {
  email: string
}

const checkMail = /^\S+@\S+$/i

const ResetTokenCard: React.FC<ResetTokenCardProps> = ({ goBack }) => {
  const [unrecognizedEmail, setUnrecognizedEmail] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [mailSent, setSentMail] = useState(false)
  const { register, handleSubmit } = useForm<FormValues>()

  const sendEmail = useAdminSendResetPasswordToken()

  const onSubmit = (values: FormValues) => {
    if (!checkMail.test(values.email)) {
      setInvalidEmail(true)
      return
    }

    setInvalidEmail(false)
    setUnrecognizedEmail(false)

    sendEmail.mutate(
      {
        email: values.email,
      },
      {
        onSuccess: () => {
          // setSentMail(true)
          navigate(`/reset-password?email=${values.email}`)
        },
        onError: () => {
          setUnrecognizedEmail(true)
        },
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center">
        <span className="inter-2xlarge-semibold mt-base text-grey-90">
          重置密码
        </span>
        <span className="text-center inter-base-regular text-grey-50 mt-xsmall">
          在下面输入您的电子邮件地址，我们将向您发送有关如何重置密码的说明。
        </span>
        {!mailSent ? (
          <>
            <SigninInput
              placeholder="lebron@james.com..."
              name="email"
              ref={register({ required: true })}
              className="mb-0 mt-xlarge"
            />
            {unrecognizedEmail && (
              <div className="mt-xsmall w-[318px]">
                <span className="text-left inter-small-regular text-rose-50">
                  我们找不到具有该电子邮件地址的用户
                </span>
              </div>
            )}
            {invalidEmail && (
              <div className="mt-xsmall w-[318px]">
                <span className="text-left inter-small-regular text-rose-50">
                  不是有效的电子邮件地址
                </span>
              </div>
            )}
            <button
              className="text-grey-0 w-[320px] h-[48px] border rounded-rounded mt-4 bg-violet-50 inter-base-regular py-3 px-4"
              type="submit"
            >
              发送
            </button>
          </>
        ) : (
          <div className="flex text-violet-60 rounded-rounded bg-violet-10 p-base gap-x-small mt-large">
            <div>
              <CheckCircleIcon size={20} />
            </div>
            <div className="flex flex-col gap-y-2xsmall">
              <span className="inter-small-semibold">
                成功地向您发送了一封电子邮件
              </span>
              <span className="inter-small-regular">
                我们已向您发送了一封电子邮件，您可以使用该电子邮件重置您的密码，如果还未收到，几分钟后再次查看您的邮箱
              </span>
            </div>
          </div>
        )}
        <span
          className="mt-8 cursor-pointer inter-small-regular text-grey-50"
          onClick={goBack}
        >
          返回登录
        </span>
      </div>
    </form>
  )
}

export default ResetTokenCard
